import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  BrowserRouter,  Routes,  Route
} from "react-router-dom";
import "./index.css";
import Main from "./pages/Main";
import Home from "./pages/Home";


ReactDOM.render(
  <BrowserRouter>
      <div>
        <p align='center'><h1>DM_predict!</h1></p>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/home" element={<Home />} />
        </Routes>
      </div>
    </BrowserRouter>,
  document.getElementById("root")
);