import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import axios from 'axios'


export default function Home() {

    const exmapleData = {
        "sex":{"0":1.0},
        "age_Base":{"0":46.0},
        "DM_duration":{"0":7.0},
        "current smoking":{"0":1.0},
        "SBP_base":{"0":120.0},
        "DBP_base":{"0":84.0},
        "bmi1":{"0":27.6},
        "wc1":{"0":94.0},
        "PLT1":{"0":280.0},
        "CHOL1":{"0":186.0},
        "AST1":{"0":17.0},
        "ALT1":{"0":28.0},
        "GGT1":{"0":41.0},
        "CR1":{"0":0.83},
        "HDL1":{"0":34.0},
        "ALB1":{"0":4.6},
        "TG1":{"0":122.0},
        "LDL1":{"0":140.0},
        "GLU1":{"0":122.0},
        "HBA1C":{"0":6.4},
        "hcv":{"0":0.0},
        "hbv":{"0":0.0},
        "Bmi25":{"0":1.0},
        "MS":{"0":1.0},
        "WC_9080":{"0":1.0},
        "tg_150":{"0":0.0},
        "HDL_4050":{"0":1.0},
        "ms_number_1.0":{"0":0.0},
        "ms_number_2.0":{"0":0.0},
        "ms_number_3.0":{"0":1.0},
        "ms_number_4.0":{"0":0.0},
        "ms_number_5.0":{"0":0.0},
        "FL_BASE_1":{"0":0.0},
        "FL_BASE_2":{"0":1.0},
        "FL_BASE_3":{"0":0.0},
        "FL_BASE_4":{"0":0.0},
        "FL_BASE_5":{"0":0.0},
        "FL_BASE_6":{"0":0.0},
        "FL2_base_1.0":{"0":1.0}
    };

    const clearData = {
        "sex":{"0":0},
        "age_Base":{"0":0},
        "DM_duration":{"0":0},
        "current smoking":{"0":0},
        "SBP_base":{"0":0},
        "DBP_base":{"0":0},
        "bmi1":{"0":0},
        "wc1":{"0":0},
        "PLT1":{"0":0},
        "CHOL1":{"0":0},
        "AST1":{"0":0},
        "ALT1":{"0":0},
        "GGT1":{"0":0},
        "CR1":{"0":0},
        "HDL1":{"0":0},
        "ALB1":{"0":0},
        "TG1":{"0":0},
        "LDL1":{"0":0},
        "GLU1":{"0":0},
        "HBA1C":{"0":0},
        "hcv":{"0":0},
        "hbv":{"0":0},
        "Bmi25":{"0":0},
        "MS":{"0":0},
        "WC_9080":{"0":0},
        "tg_150":{"0":0},
        "HDL_4050":{"0":0},
        "ms_number_1.0":{"0":0},
        "ms_number_2.0":{"0":0},
        "ms_number_3.0":{"0":0},
        "ms_number_4.0":{"0":0},
        "ms_number_5.0":{"0":0},
        "FL_BASE_1":{"0":0},
        "FL_BASE_2":{"0":0},
        "FL_BASE_3":{"0":0},
        "FL_BASE_4":{"0":0},
        "FL_BASE_5":{"0":0},
        "FL_BASE_6":{"0":0},
        "FL2_base_1.0":{"0":0}
    };

    const [patData, setPatData] = useState(clearData);

    const [predict, setPredict] = useState({
        get_result : "",
        is_dm : "",
        dm_prb : "",
        image : ""
    });

    const [msNumber, setMsNumber] = useState(0);
    const [FLBase,setFLBase] = useState(0);

    const handleClickClear = () => {
        setPatData(clearData)        
        setPredict({
            get_result : "",
            is_dm : "",
            dm_prb : "",
            image : ""
        })
    };

    const handleClickExample = () => {
        setPatData(exmapleData)        
    };

    const handleClickSend = () => {
        setPredict({
            get_result : "",
            is_dm :"",
            dm_prb : "",
            image : ""
        })     
        axios.post( 'http://mdhi.co.kr:3000/h2o/predict/predict_dm', {title : "test", data : JSON.stringify(patData)}, 
        { 
        headers:{ 
        'Content-type': 'application/json', 
        'Accept': 'application/json' ,
        'Access-Control-Allow-Origin' : '*',
            } 
        })
        .then((response) => {
            // console.log(response.data)
            // console.log(response)
            setPredict({
                get_result : "True",
                is_dm :JSON.parse(JSON.parse(response.data).predict).predict["0"],
                dm_prb : JSON.parse(JSON.parse(response.data).predict).p1["0"],
                image : JSON.parse(response.data).image
            })
            return JSON.parse(response.data)
        // .catch((response) => {console.log('Error! Cant not get predicted results.')})
        })
    };

    const handleChangeSex = (event) => {
        setPatData({...patData,"sex":{"0":parseInt(event.target.value)}})        
    };

    const handleChangeAge = (event) => {
        setPatData({...patData,"age_Base":{"0":parseInt(event.target.value)}})        
    };

    const handleChangeDMDuration = (event) => {
        setPatData({...patData, "DM_duration":{"0":parseInt(event.target.value)}})
    }

    const handleChangeCurrentSmoking = (event) => {
        setPatData({...patData, "current smoking":{"0":parseInt(event.target.value)}})
    }

    const handleChangeSBPBase = (event) => {
        setPatData({...patData, "SBP_base":{"0":parseInt(event.target.value)}})
    }

    const handleChangeDBPBase = (event) => {
        setPatData({...patData, "DBP_base":{"0":parseInt(event.target.value)}})
    }

    const handleChangeBmi1 = (event) => {
        setPatData({...patData, "bmi1":{"0":parseInt(event.target.value)}})
    }

    const handleChangeWc1 = (event) => {
        setPatData({...patData, "wc1":{"0":parseInt(event.target.value)}})
    }

    const handleChangePLT1 = (event) => {
        setPatData({...patData, "PLT1":{"0":parseInt(event.target.value)}})
    }

    const handleChangeCHOL1 = (event) => {
        setPatData({...patData, "CHOL1":{"0":parseInt(event.target.value)}})
    }

    const handleChangeAST1 = (event) => {
        setPatData({...patData, "AST1":{"0":parseInt(event.target.value)}})
    }

    const handleChangeALT1 = (event) => {
        setPatData({...patData, "ALT1":{"0":parseInt(event.target.value)}})
    }

    const handleChangeGGT1 = (event) => {
        setPatData({...patData, "GGT1":{"0":parseInt(event.target.value)}})
    }

    const handleChangeCR1 = (event) => {
        setPatData({...patData, "CR1":{"0":parseInt(event.target.value)}})
    }

    const handleChangeHDL1 = (event) => {
        setPatData({...patData, "HDL1":{"0":parseInt(event.target.value)}})
    }

    const handleChangeALB1 = (event) => {
        setPatData({...patData, "ALB1":{"0":parseInt(event.target.value)}})
    }

    const handleChangeTG1 = (event) => {
        setPatData({...patData, "TG1":{"0":parseInt(event.target.value)}})
    }

    const handleChangeLDL1 = (event) => {
        setPatData({...patData, "LDL1":{"0":parseInt(event.target.value)}})
    }

    const handleChangeGLU1 = (event) => {
        setPatData({...patData, "GLU1":{"0":parseInt(event.target.value)}})
    }

    const handleChangeHBA1C = (event) => {
        setPatData({...patData, "HBA1C":{"0":parseInt(event.target.value)}})
    }

    const handleChangeHCV = (event) => {
        setPatData({...patData, "hcv":{"0":parseInt(event.target.value)}})
    }

    const handleChangeHBV = (event) => {
        setPatData({...patData, "hbv":{"0":parseInt(event.target.value)}})
    }

    const handleChangeBmi25 = (event) => {
        setPatData({...patData, "Bmi25":{"0":parseInt(event.target.value)}})
    }

    const handleChangeMS = (event) => {
        setPatData({...patData, "MS":{"0":parseInt(event.target.value)}})
    }

    const handleChangeWC9080 = (event) => {
        setPatData({...patData, "WC_9080":{"0":parseInt(event.target.value)}})
    }

    const handleChangeTG150 = (event) => {
        setPatData({...patData, "tg_150":{"0":parseInt(event.target.value)}})
    }

    const handleChangeHDL4050 = (event) => {
        setPatData({...patData, "HDL_4050":{"0":parseInt(event.target.value)}})
    }

    const handleChangeMSNumber = (event) => {
        setMsNumber(event.target.value);
        switch (event.target.value){
            case 0 : return(
                setPatData({...patData, 
                    "ms_number_1.0" : {"0":0},
                    "ms_number_2.0" : {"0":0},
                    "ms_number_3.0" : {"0":0},
                    "ms_number_4.0" : {"0":0},
                    "ms_number_5.0" : {"0":0},
                }))
            case 1:return(
                setPatData({...patData, 
                    "ms_number_1.0" : {"0":1},
                    "ms_number_2.0" : {"0":0},
                    "ms_number_3.0" : {"0":0},
                    "ms_number_4.0" : {"0":0},
                    "ms_number_5.0" : {"0":0},
                }))
            case 2 :return(
                setPatData({...patData, 
                    "ms_number_1.0" : {"0":0},
                    "ms_number_2.0" : {"0":1},
                    "ms_number_3.0" : {"0":0},
                    "ms_number_4.0" : {"0":0},
                    "ms_number_5.0" : {"0":0},
                }))
            case 3 :return(
                setPatData({...patData, 
                    "ms_number_1.0" : {"0":0},
                    "ms_number_2.0" : {"0":0},
                    "ms_number_3.0" : {"0":1},
                    "ms_number_4.0" : {"0":0},
                    "ms_number_5.0" : {"0":0},
                }))
            case 4 :return(
                setPatData({...patData, 
                    "ms_number_1.0" : {"0":0},
                    "ms_number_2.0" : {"0":0},
                    "ms_number_3.0" : {"0":0},
                    "ms_number_4.0" : {"0":1},
                    "ms_number_5.0" : {"0":0},
                }))
            case 5 :return(
                setPatData({...patData, 
                    "ms_number_1.0" : {"0":0},
                    "ms_number_2.0" : {"0":0},
                    "ms_number_3.0" : {"0":0},
                    "ms_number_4.0" : {"0":0},
                    "ms_number_5.0" : {"0":1},
                }))
        }
    }

    const HandleChangeFLBase = (event) => {
        setFLBase(event.target.value);
        switch (event.target.value){
            case 0:return(
                setPatData({...patData,
                    "FL_BASE_1" : {"0":0},
                    "FL_BASE_2" : {"0":0},
                    "FL_BASE_3" : {"0":0},
                    "FL_BASE_4" : {"0":0},
                    "FL_BASE_5" : {"0":0},
                    "FL_BASE_6" : {"0":0},              
                }))
            case 1:return(
                setPatData({...patData,
                    "FL_BASE_1" : {"0":1},
                    "FL_BASE_2" : {"0":0},
                    "FL_BASE_3" : {"0":0},
                    "FL_BASE_4" : {"0":0},
                    "FL_BASE_5" : {"0":0},
                    "FL_BASE_6" : {"0":0},                 
                }))
            case 2:return(
                setPatData({...patData,
                    "FL_BASE_1" : {"0":0},
                    "FL_BASE_2" : {"0":1},
                    "FL_BASE_3" : {"0":0},
                    "FL_BASE_4" : {"0":0},
                    "FL_BASE_5" : {"0":0},
                    "FL_BASE_6" : {"0":0},                 
                }))
            case 3:return(
                setPatData({...patData,
                    "FL_BASE_1" : {"0":0},
                    "FL_BASE_2" : {"0":0},
                    "FL_BASE_3" : {"0":1},
                    "FL_BASE_4" : {"0":0},
                    "FL_BASE_5" : {"0":0},
                    "FL_BASE_6" : {"0":0},                 
                }))
            case 4:return(
                setPatData({...patData,
                    "FL_BASE_1" : {"0":0},
                    "FL_BASE_2" : {"0":0},
                    "FL_BASE_3" : {"0":0},
                    "FL_BASE_4" : {"0":1},
                    "FL_BASE_5" : {"0":0},
                    "FL_BASE_6" : {"0":0},                  
                }))
            case 5:return(
                setPatData({...patData,
                    "FL_BASE_1" : {"0":0},
                    "FL_BASE_2" : {"0":0},
                    "FL_BASE_3" : {"0":0},
                    "FL_BASE_4" : {"0":0},
                    "FL_BASE_5" : {"0":1},
                    "FL_BASE_6" : {"0":0},               
                }))
            case 6:return(
                setPatData({...patData,
                    "FL_BASE_1" : {"0":0},
                    "FL_BASE_2" : {"0":0},
                    "FL_BASE_3" : {"0":0},
                    "FL_BASE_4" : {"0":0},
                    "FL_BASE_5" : {"0":0},
                    "FL_BASE_6" : {"0":1},                    
                }))
        }
    }

    const handleChangeFL2Base = (event) => {
        setPatData({...patData, "FL2_base_1.0":{"0":parseInt(event.target.value)}})
    }


    // useEffect(()=> {
    //     console.log("patData : ",patData)        
    // },[patData])

    useEffect(()=> {
        console.log("predict : ",predict)
    },[predict])

    return (
      <>   
             
        <div style={{marginLeft:'40px'}}>            
          <p>You can do this, I believe in you.</p>          
        </div>

        {/* 본문부 */}
        <div style={{flex: 1, flexDirection: 'column', margin:'10px'}}>
            {/* 좌측 뷰 */}
            <div style={{float:"left", width:"450px"}} id="left_div">
                {/* 좌하좌 */}
                <div style={{float:"left", width:"50%"}} id="left_div1">

                    <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                // justifyContent="center"
                // style={{ minHeight: '100vh' }}
            >
                <Box 
                    component="form"
                    sx={{
                        '& .InputValuesTextField-root':{m:1, width: '25ch'},
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div style={{margin:'20px', width:'200px', marginTop:'20px'}}>
                        {/* 나이 */}
                        <TextField
                            label="Age"
                            onChange={handleChangeAge}
                            id="text-field-age"
                            value={patData.age_Base["0"]}                            
                            size="small" 
                        />

                        

                        {/* 성별 */}
                        <div style={{marginTop:'20px'}} />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Sex</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={patData.sex["0"]}
                                label="Sex"
                                onChange={handleChangeSex}
                                size="small"
                            >                        
                                <MenuItem value={0}>Male</MenuItem>
                                <MenuItem value={1}>Female</MenuItem>
                            </Select>
                        </FormControl>

                        {/* [TODO] : handle !!!!!!!!!! */}

                        {/* DM_duration */}
                        <div style={{marginTop:'20px'}} />
                        <TextField
                            label="DM_duration"
                            onChange={handleChangeDMDuration}
                            id="text-field-dm-duration"
                            value={patData.DM_duration["0"]}                            
                            size="small" 
                        />


                        {/* 흡연 */}
                        <div style={{marginTop:'20px'}} />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">current smoking</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select-current-smoking"
                                value={patData["current smoking"]["0"]}
                                label="current smoking"
                                onChange={handleChangeCurrentSmoking}
                                size="small"
                            >                        
                                <MenuItem value={0}>No</MenuItem>
                                <MenuItem value={1}>Yes</MenuItem>
                            </Select>
                        </FormControl>


                        {/* SBP_base */}
                        <div style={{marginTop:'20px'}} />
                        <TextField
                            label="SBP_base"
                            onChange={handleChangeSBPBase}
                            id="text-field-sbp-base"
                            value={patData.SBP_base["0"]}                            
                            size="small" 
                        />


                        {/* DBP_base */}
                        <div style={{marginTop:'20px'}} />
                        <TextField
                            label="DBP_base"
                            onChange={handleChangeDBPBase}
                            id="text-field-dbp-base"
                            value={patData.DBP_base["0"]}                            
                            size="small" 
                        />


                        {/* bmi1 */}
                        <div style={{marginTop:'20px'}} />
                        <TextField
                            label="bmi1"
                            onChange={handleChangeBmi1}
                            id="text-field-bmi1"
                            value={patData.bmi1["0"]}                            
                            size="small" 
                        />


                        {/* wc1 */}
                        <div style={{marginTop:'20px'}} />
                        <TextField
                            label="wc1"
                            onChange={handleChangeWc1}
                            id="text-field-wc1"
                            value={patData.wc1["0"]}                            
                            size="small" 
                        />


                        {/* PLT1 */}
                        <div style={{marginTop:'20px'}} />
                        <TextField
                            label="PLT1"
                            onChange={handleChangePLT1}
                            id="text-field-PLT1"
                            value={patData.PLT1["0"]}                            
                            size="small" 
                        />


                        {/* CHOL1 */}
                        <div style={{marginTop:'20px'}} />
                        <TextField
                            label="CHOL1"
                            onChange={handleChangeCHOL1}
                            id="text-field-CHOL1"
                            value={patData.CHOL1["0"]}                            
                            size="small" 
                        />

                        {/* AST1 */}
                        <div style={{marginTop:'20px'}} />
                        <TextField
                            label="AST1"
                            onChange={handleChangeAST1}
                            id="text-field-AST1"
                            value={patData.AST1["0"]}                            
                            size="small" 
                        />

                        {/* ALT1 */}
                        <div style={{marginTop:'20px'}} />
                        <TextField
                            label="ALT1"
                            onChange={handleChangeALT1}
                            id="text-field-ALT1"
                            value={patData.ALT1["0"]}                            
                            size="small" 
                        />

                        {/* GGT1 */}
                        <div style={{marginTop:'20px'}} />
                        <TextField
                            label="GGT1"
                            onChange={handleChangeGGT1}
                            id="text-field-GGT1"
                            value={patData.GGT1["0"]}                            
                            size="small" 
                        />

                        {/* CR1 */}
                        <div style={{marginTop:'20px'}} />
                        <TextField
                            label="CR1"
                            onChange={handleChangeCR1}
                            id="text-field-CR1"
                            value={patData.CR1["0"]}                            
                            size="small" 
                        />

                        {/* HDL1 */}
                        <div style={{marginTop:'20px'}} />
                        <TextField
                            label="HDL1"
                            onChange={handleChangeHDL1}
                            id="text-field-HDL1"
                            value={patData.HDL1["0"]}                            
                            size="small" 
                        />
                                                
                    </div>
                </Box>
                
                
    
    </Grid>                    

                </div>
                
                {/* 좌하우 */}
                <div style={{float:"left", width:"50%"}} id="left_div1">

                <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                // justifyContent="center"
                // style={{ minHeight: '100vh' }}
            >
                <Box 
                    component="form"
                    sx={{
                        '& .InputValuesTextField-root':{m:1, width: '25ch'},
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div style={{margin:'20px', width:'200px', marginTop:'20px'}}>                        
                        {/* ALB1 */}
                        <div style={{marginTop:'20px'}} />
                        <TextField
                            label="ALB1"
                            onChange={handleChangeALB1}
                            id="text-field-ALB1"
                            value={patData.ALB1["0"]}                            
                            size="small" 
                        />


                        {/* TG1 */}
                        <div style={{marginTop:'20px'}} />
                        <TextField
                            label="TG1"
                            onChange={handleChangeTG1}
                            id="text-field-TG1"
                            value={patData.TG1["0"]}                            
                            size="small" 
                        />


                        {/* LDL1 */}
                        <div style={{marginTop:'20px'}} />
                        <TextField
                            label="LDL1"
                            onChange={handleChangeLDL1}
                            id="text-field-LDL1"
                            value={patData.LDL1["0"]}                            
                            size="small" 
                        />


                        {/* GLU1 */}
                        <div style={{marginTop:'20px'}} />
                        <TextField
                            label="GLU1"
                            onChange={handleChangeGLU1}
                            id="text-field-GLU1"
                            value={patData.GLU1["0"]}                            
                            size="small" 
                        />

                        {/* HBA1C */}
                        <div style={{marginTop:'20px'}} />
                        <TextField
                            label="HBA1C"
                            onChange={handleChangeHBA1C}
                            id="text-field-HBA1C"
                            value={patData.HBA1C["0"]}                            
                            size="small" 
                        />

                        {/* hcv */}
                        <div style={{marginTop:'20px'}} />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label-hcv">hcv</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-hcv"
                                id="demo-simple-select-current-hcv"
                                value={patData["hcv"]["0"]}
                                label="hcv"
                                onChange={handleChangeHCV}
                                size="small"
                            >                        
                                <MenuItem value={0}>Negative</MenuItem>
                                <MenuItem value={1}>Positive</MenuItem>
                            </Select>
                        </FormControl>



                        {/* hbv */}
                        <div style={{marginTop:'20px'}} />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label-hbv">hbv</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-hbv"
                                id="demo-simple-select-current-hbv"
                                value={patData["hbv"]["0"]}
                                label="hbv"
                                onChange={handleChangeHBV}
                                size="small"
                            >                        
                                <MenuItem value={0}>Negative</MenuItem>
                                <MenuItem value={1}>Positive</MenuItem>
                            </Select>
                        </FormControl>


                        {/* Bmi25 */}
                        <div style={{marginTop:'20px'}} />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label-Bmi25">Bmi25</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-Bmi25"
                                id="demo-simple-select-current-Bmi25"
                                value={patData["Bmi25"]["0"]}
                                label="Bmi25"
                                onChange={handleChangeBmi25}
                                size="small"
                            >                        
                                <MenuItem value={0}>No</MenuItem>
                                <MenuItem value={1}>Yes</MenuItem>
                            </Select>
                        </FormControl>



                        {/* MS */}
                        <div style={{marginTop:'20px'}} />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label-MS">MS</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-MS"
                                id="demo-simple-select-current-MS"
                                value={patData["MS"]["0"]}
                                label="MS"
                                onChange={handleChangeMS}
                                size="small"
                            >                        
                                <MenuItem value={0}>No</MenuItem>
                                <MenuItem value={1}>Yes</MenuItem>
                            </Select>
                        </FormControl>


                        {/* WC_9080 */}
                        <div style={{marginTop:'20px'}} />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label-WC_9080">WC_9080</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-WC_9080"
                                id="demo-simple-select-current-WC_9080"
                                value={patData["WC_9080"]["0"]}
                                label="WC_9080"
                                onChange={handleChangeWC9080}
                                size="small"
                            >                        
                                <MenuItem value={0}>No</MenuItem>
                                <MenuItem value={1}>Yes</MenuItem>
                            </Select>
                        </FormControl>


                        {/* tg_150 */}
                        <div style={{marginTop:'20px'}} />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label-tg_150">tg_150</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-tg_150"
                                id="demo-simple-select-current-tg_150"
                                value={patData["tg_150"]["0"]}
                                label="tg_150"
                                onChange={handleChangeTG150}
                                size="small"
                            >                        
                                <MenuItem value={0}>No</MenuItem>
                                <MenuItem value={1}>Yes</MenuItem>
                            </Select>
                        </FormControl>



                        {/* HDL_4050 */}
                        <div style={{marginTop:'20px'}} />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label-HDL_4050">HDL_4050</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-HDL_4050"
                                id="demo-simple-select-current-HDL_4050"
                                value={patData["HDL_4050"]["0"]}
                                label="HDL_4050"
                                onChange={handleChangeHDL4050}
                                size="small"
                            >                        
                                <MenuItem value={0}>No</MenuItem>
                                <MenuItem value={1}>Yes</MenuItem>
                            </Select>
                        </FormControl>


                        {/* ms_number */}
                        <div style={{marginTop:'20px'}} />                        
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label-ms_number">ms_number</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-ms_number"
                                id="demo-simple-select-current-ms_number"
                                value={msNumber}
                                label="ms_number"
                                onChange={handleChangeMSNumber}
                                size="small"
                            >                        
                                <MenuItem value={0}>0</MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                            </Select>
                        </FormControl>


                        {/* FL_BASE */}
                        <div style={{marginTop:'20px'}} />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label-FL_BASE">FL_BASE</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-FL_BASE"
                                id="demo-simple-select-current-FL_BASE"
                                value={FLBase}
                                label="FL_BASE"
                                onChange={HandleChangeFLBase}
                                size="small"
                            >                        
                                <MenuItem value={0}>0</MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                            </Select>
                        </FormControl>

                        {/* FL2_BASE */}
                        <div style={{marginTop:'20px'}} />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label-FL2_BASE">FL2_BASE</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-FL2_BASE"
                                id="demo-simple-select-current-FL2_BASE"
                                value={patData["FL2_base_1.0"]["0"]}
                                label="FL2_BASE"
                                onChange={handleChangeFL2Base}
                                size="small"
                            >                        
                                <MenuItem value={0}>0</MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                            </Select>
                        </FormControl>

                        
                    </div>
                </Box>
                
                
    
    </Grid>

                </div>
          
    <div style={{marginBottom:'20px'}} />
            </div>


    {/* 가운데 */}
    
    <div style={{width:"200px",  float:"left", justifyContent:"center", alignItems:"center"}} id="right_div">
    <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '70vh' }}
            >
            <Stack direction="column" spacing={2} align="center">
                <Button variant="outlined" onClick={handleClickClear}>
                    Clear
                </Button>
                <Button variant="outlined" onClick={handleClickExample}>
                    Example
                </Button>
                <Button variant="contained" onClick={handleClickSend}>
                    Send ▷
                </Button>
            </Stack>

    </Grid>
                
                </div>


    {/* 여기부터오른쪽 */}
            <div style={{flex:1, float:"left"}} id="right_div">
                <div>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    // justifyContent="center"
                    // style={{ minHeight: '100vh' }}
                >
                    
                    {predict.image ?
                    <> 
                    <h1>                         
                        Predicted Result is {predict.is_dm?<> DM </>: <>Not DM</>}
                        <br/>
                        DM Probability is {predict.dm_prb}
                        <o height="50px"/>
                    </h1>    
                    
                    <img src={`data:image/jpeg;base64,${predict.image}`} width='95%' /> 
                    </>:
                     ""}
                    
                </Grid>
                </div>
            </div>
        </div>
        
        {/* <nav>
          
          <br />
          <Link to="/home"><button> Submit </button></Link>
        </nav> */}
      </>
    );
  }
