import { useEffect, useState } from 'react'; 
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';


function Home() {
  const navigate = useNavigate();
  const [predict,setPredict] = useState('');
  const [p1Percent,setP1Percent] = useState('');
  const [reasonImage,setReasonImage] = useState('');
  const ismount = ''
  const example_data = {
    "sex":{"0":1.0},
    "age_Base":{"0":46.0},
    "DM_duration":{"0":7.0},
    "current smoking":{"0":1.0},
    "SBP_base":{"0":120.0},
    "DBP_base":{"0":84.0},
    "bmi1":{"0":27.6},
    "wc1":{"0":94.0},
    "PLT1":{"0":280.0},
    "CHOL1":{"0":186.0},
    "AST1":{"0":17.0},
    "ALT1":{"0":28.0},
    "GGT1":{"0":41.0},
    "CR1":{"0":0.83},
    "HDL1":{"0":34.0},
    "ALB1":{"0":4.6},
    "TG1":{"0":122.0},
    "LDL1":{"0":140.0},
    "GLU1":{"0":122.0},
    "HBA1C":{"0":6.4},
    "hcv":{"0":0.0},
    "hbv":{"0":0.0},
    "Bmi25":{"0":1.0},
    "MS":{"0":1.0},
    "WC_9080":{"0":1.0},
    "tg_150":{"0":0.0},
    "HDL_4050":{"0":1.0},
    "ms_number_1.0":{"0":0.0},
    "ms_number_2.0":{"0":0.0},
    "ms_number_3.0":{"0":1.0},
    "ms_number_4.0":{"0":0.0},
    "ms_number_5.0":{"0":0.0},
    "FL_BASE_1":{"0":0.0},
    "FL_BASE_2":{"0":1.0},
    "FL_BASE_3":{"0":0.0},
    "FL_BASE_4":{"0":0.0},
    "FL_BASE_5":{"0":0.0},
    "FL_BASE_6":{"0":0.0},
    "FL2_base_1.0":{"0":1.0}
  }
  
  useEffect(() =>{
    axios.post( 'http://mdhi.co.kr:3000/h2o/predict/is_dm_with_image', 
    example_data, 
    { 
    headers:{ 
      'Content-type': 'application/json', 
      'Accept': 'application/json' 
        } 
      } 
    ).then((response) => { 
      setReasonImage(JSON.parse(response.data).image)
      return JSON.parse(response.data).predict }
    ).then((response_data)=>{
      setPredict(JSON.parse(response_data).predict["0"])
      setP1Percent(JSON.parse(response_data).p1["0"])
    })
    .catch((response) => { console.log('Error! Can not get predicted results.') });
  },[])
  
  return (
    <>
      <main>
        <h2>This is your result.</h2>             
        
        {!predict ? 
        <b>Now Loading...</b> 
        : <b>Your Predicted Result is {predict ?  <b>DM</b> : <b>no DM</b> } 
        <br />
         And Your DM_Percent is {Math.floor(parseFloat(p1Percent)*100)/100}
         <Box sx={{ width: '215px' }}>
            <LinearProgress color="secondary" variant="determinate" value={Math.floor(parseFloat(p1Percent)*100)} />
         </Box>
         <br />
         And AI focused on this.
         <br />
         <img src={`data:image/jpeg;base64,${reasonImage}`} width='600px' />
        </b>}
        
      </main>
      <nav>
          <button onClick={() => navigate(-1)}>go back</button>
        <br />
        {/* <Link to="/home">Home</Link> */}
      </nav>
    </>
  );
}


export default Home;